<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.sysRoles')"
          icon="mdi-apple-finder"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
                clearable
              />

              <v-spacer />

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-toolbar-items>
          </v-sheet>

          <TableImg
            :head="headers"
            :data="datas"
            :search="search"
            :tableLoading.sync="tableLoading"
            v-on:edititem="handleEditDialog"
            v-on:deleteitem="handleDeleteDialog"
          />
        </base-material-card>
      </v-col>
    </v-row>

    <RoleCRUD
      :dialog.sync="dialog"
      :myObj="obj"
      @handleData="handleRoleConfirm"
    />

    <DialogDelete
      :dialogDelete.sync="dialogDelete"
      :deleteObj="obj"
      @handleDelete="handleDeleteConfirm"
    />
  </v-container>
</template>

<script>
import { roleList, roleDel, roleUpsert } from "@/api/system";

const newSearch = () => {
  return {
    type_code: "",
    skip: 0,
    limit: 10,
    key: "",
  };
};

const newObj = () => {
  return {
    id: "",
    name: "",
    functions: {},
    note: "",
    level: 0,
  };
};

export default {
  name: "role",
  components: {
    TableImg: () => import("@/components/tableImg"),
    RoleCRUD: () => import("@/components/system/roleCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.sysMgr") },
        { text: this.$vuetify.lang.t("$vuetify.sysRoles") },
      ],
      searchForm: newSearch(),
      obj: newObj(),
      dialog: false,
      dialogDelete: false,
      search: "",
      datas: [],
      tableLoading: true,
    };
  },
  methods: {
    getData() {
      roleList(this.searchForm)
        .then((data) => {
          this.tableLoading = false;
          if (data.items != null) {
            this.datas = [...data.items];

            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
            }
          } else {
            this.datas = [];
            this.$toast.info("No Data Found", { timeout: 2000 });
          }
        })
        .catch((err) => {
          console.log("Order List Error", err);
        });
    },
    handleDialog() {
      this.dialog = true;
      this.obj = newObj();
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.obj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      roleDel(obj.id)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("Role Delete", err));
    },
    handleRoleConfirm(obj, funcData) {
      this.dialog = false;
      this.obj = obj;
      this.setForm(funcData);
      this.upsertRole();
    },
    setForm(funcData) {
      funcData.forEach((item) => {
        var json = {
          id: item.id,
          name: item.name,
          code: item.code,
          list: item.list,
          add: item.add,
          del: item.del,
          modify: item.modify,
          row: item.row_choice.length !== 0,
          row_fields: item.row_choice,
          column: item.column_choice.length !== 0,
          column_fields: item.column_choice,
        };
        this.obj.functions[item.code] = json;
      });
    },
    upsertRole() {
      this.obj.functions = JSON.stringify(this.obj.functions);
      roleUpsert(this.obj)
        .then(() => this.getData())
        .catch((err) => console.log("Error Role: ", err));
    },
  },
  mounted() {
    this.getData();
  },
};
</script>